<div class="col col-10 shadow my-2 border mx-auto"
    style="border-radius: 5px; background-color: rgba(52, 159, 221, 0.582);">
    
    <div class="card mx-auto my-4 shadow" style="background-color: rgba(255, 255, 255, 0.842); font-size: calc(100%)">
        <div class="container p-2">
            <div class="row  mx-auto" style="width: 100%">
                <div class="col col-6 mx-auto">
                    <img class="my-2 p-2 mx-auto img-responsive shadow" style="border-radius: 5%; width: 100%"
                        src="assets/undraw_page_not_found_su7k.svg">

                </div>
                
            </div>
            <div class="row mx-auto my-1" style="width: 100%">
                <h2 class="lead text text-center" style="font-style: italic; font-weight: bold; font-size: calc(100% + 1vh)">
                    Oh No!
                </h2>
                
            </div>
            <hr>
                <p class="lead text-sm-center text-lg-left" style="font-size: calc(100%)">
                    The page you requested was not found. Check the URL or come back later to see if we fixed the issue


                </p>
        </div>

    </div>

    


</div>