<!--Navbar-->
<style>
    * {
        color: aliceblue;
    }
</style>
<nav id="topNav" class="shadow" (mouseleave)="mouseLeave('mainNavbar');"
    style=" background-color: rgba(37, 121, 170, 0.95)">



    <!-- Collapse button -->
    <div class="row px-2 " style="max-width: 100% !important">
        <button class="ml-2 my-auto navbar-toggler" (mouseenter)="mouseEnter('mainNavbar')" type="button"
            data-toggle="collapse" data-target="#mainNavbar" aria-controls="mainNavbar" aria-expanded="false"
            aria-label="Toggle navigation"><span>
                <img [ngStyle]="{'height.px': .05*winHeight}"
                    src="https://img.icons8.com/ios/100/000000/menu--v1.png" />



            </span></button>
        <p class=" my-auto" [ngStyle]="{'font-size.vw': .7*fontSize, 'font-style': 'italic'}">Menu</p>
        <a style="visibility: hidden; z-index: -20" href="https://icons8.com/icon/3096/menu">Menu icon by Icons8</a>
    </div>

    <!-- Collapsible content -->
    <div class="collapse  py-2 navbar-dark navbar-collapse col col-12 shadow" id="mainNavbar"
        (mouseleave)="mouseLeave('mainNavbar')"
        style="background-color: rgba(37, 121, 170, 0.95); position: absolute; z-index: 100; border-radius: 0px 0px 5px 5px;">

        <!-- Links -->
        <ul class="navbar-nav px-auto mb-3 mx-auto" [ngStyle]="{'min-height.px': .25*winHeight}">
            <li class="mx-auto nav-item col-12" (mouseenter)="mouseLeave('projNavbar')" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact:true}">
                <a class="text text-left nav-link" [ngStyle]="{'font-size.vw': .7*fontSize, 'font-style': 'italic'}"
                    routerLink="/" (click)="closeAll(['projNavbar', 'mainNavbar'])">Home</a>

            </li>
            <li class="mx-auto nav-item col-12">
                <a class="text text-left nav-link" [ngStyle]="{'font-size.vw': .7*fontSize, 'font-style': 'italic'}"
                    routerLink="/resume" (click)="closeAll(['projNavbar', 'mainNavbar'])">Resume</a>

            </li>
            <li class="mx-auto nav-item col-12">
                <a class="text text-left nav-link" [ngStyle]="{'font-size.vw': .7*fontSize, 'font-style': 'italic'}"
                    routerLink="/guest-book" (click)="closeAll(['projNavbar', 'mainNavbar'])">Guest Book</a>

            </li>
            <button class="navbar-toggler" (mouseenter)="mouseEnter('projNavbar')" type="button" data-toggle="collapse"
                data-target="#projNavbar" aria-controls="projNavbar" aria-expanded="false"
                aria-label="Toggle navigation" style="border: none">
                <span class="nav-item text text-left nav-link"
                    [ngStyle]="{'font-size.vw': fontSize, 'font-style': 'italic'}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact:true}">
                    <a class="text text-left nav-link" [ngStyle]="{'font-size.vw': .7*fontSize, 'font-style': 'italic'}"
                        routerLink="/projects" (click)="closeAll(['projNavbar', 'mainNavbar'])">Projects</a>
                </span>
            </button>
            <div id="projNavbar" #projNavbar (mouseleave)="mouseLeave('projNavbar')" class="collapse navbar-dark">
                <ul class="navbar-nav">
                    <li class="nav-item mr-auto" [ngStyle]="{'width.px': .25*max(winWidth, winHeight)}"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class=" text text-left nav-link mx-auto"
                            [ngStyle]="{'font-size.vw': fontSize*.5, 'font-style': 'italic', 'width': '50%'}"
                            routerLink="/projects/graphics"
                            (click)="closeAll(['projNavbar', 'mainNavbar'])">Graphics</a>

                    </li>
                    <li class="nav-item mr-auto" [ngStyle]="{'width.px': .25*max(winWidth, winHeight)}"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="text text-left mx-auto nav-link"
                            [ngStyle]="{'font-size.vw': fontSize*.5, 'font-style': 'italic',  'width': '50%'}"
                            routerLink="/projects/ai" (click)="closeAll(['projNavbar', 'mainNavbar'])">AI/ML</a>

                    </li>
                    <li class="nav-item mr-auto" [ngStyle]="{'width.px': .25*max(winWidth, winHeight)}"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="text text-left mx-auto nav-link"
                            [ngStyle]="{'font-size.vw': fontSize*.5, 'font-style': 'italic',  'width': '50%'}"
                            routerLink="/projects/web" (click)="closeAll(['projNavbar', 'mainNavbar'])">Web</a>

                    </li>
                </ul>
            </div>

        </ul>
        <!-- Links -->

    </div>
    <!-- Collapsible content -->
</nav>
<!--/.Navbar-->