<head>
    <style>
        /* Set a background image by replacing the URL below */
        html {
            /* height: 100vh; */
        }

        body {
            /* min-height: 100vh; */

            background: url('assets/287_abstract-blue-business-technology-colorful-wave-illustration-[Converted].jpg') no-repeat center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            background-size: cover;
            -o-background-size: cover;
        }
    </style>
</head>

<body class="pb-1" [ngStyle]="{'min-height.px': winHeight}">
    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <a style="visibility: hidden;" href="https://www.vecteezy.com/free-vector/abstract-background">Abstract Background
        Vectors by Vecteezy</a>
    <router-outlet></router-outlet>
    


</body>
<app-footer></app-footer>