<div class="col col-10 shadow my-2 border mx-auto"
    style="border-radius: 5px; background-color: rgba(52, 159, 221, 0.582);">
    <h1 class="p-2 m-2 jumbotron mx-auto text-center"
        style="background-color: rgba(255, 255, 255, 0.842); font-style: italic; font-weight: bold">
        Welcome</h1>
    <hr>
    <div class="card mx-auto my-4 shadow" style="background-color: rgba(255, 255, 255, 0.842); font-size: calc(100%)">
        <div class="container p-2">
            <div class="row  mx-auto" style="width: 100%">
                <div class="col col-6 mx-auto">
                    <img class="my-2 mx-auto img-responsive shadow" style="border-radius: 50%; width: 100%"
                        src="assets/BlurredPhoto.png">

                </div>
                
            </div>
            <div class="row mx-auto my-1" style="width: 100%">
                <h2 class="lead text text-center" style="font-style: italic; font-weight: bold; font-size: calc(100% + 1vh)">
                    Thank you for visiting my portfolio page!
                </h2>
                <p class="lead text-sm-center text-lg-left" style="font-size: calc(100%)">
                        I am an undergradaute alumni from University of California Santa Cruz (TIM/CS Minor '21)
                    currently working in software engineering. I have been coding since 2011 mainly using Java/JS, Python, HTML/CSS and 
                    C/C++ for app and web design. I also have some experience using SQL and setting up RDBMS for persisting data and am
                    comfortable managing GitHub repositories. I also have some experience in Agile projects both in the role of Scrummaster
                    and programmer.  
                    <br><br>
                        In the last two years I've focused on web and cross platform app development and developed a 
                    keen interest in AI/Machine Learning. This portfolio page is in part a demonstration of my code 
                    portfolio as well as an example of my experience in web development using the MEAN stack. I hope 
                    you enjoy browsing my projects and be sure to check out my LinkedIn/Github links at the bottom
                    of the page, let's connect!


                </p>
            </div>
        </div>

    </div>

    


</div>