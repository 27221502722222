<nav class="navbar navbar-expand-sm py-4 py-large-5 navbar-dark shadow" style=" background-color: rgba(52, 159, 221, 0.582);">
    <div class="container col col-12">
        
            <a class="navbar-brand mr-auto"  routerLink="/">
                <div class="row" style="height: 5vh; background-color: rgba(0,0,0,0);">
                    <img class="my-auto" style="object-fit: contain; height: inherit" src="assets/sonic_spray.png" />
                    <p class="my-auto" style="font-size: calc(100%)">robertsilver.codes</p>
                </div>
            </a>
        
    </div>

</nav>
