

<div class="col col-11 shadow my-2 border mx-auto"
style="border-radius: 5px; background-color: rgba(52, 159, 221, 0.582);">
<h1 class="p-2 m-2 jumbotron mx-auto text-center"
    style="background-color: rgba(255, 255, 255, 0.842); font-style: italic; font-weight: bold">
    WEB </h1>
    <div class="container pt-2 pb-0 col col-12">
        <section class="lead card text-left mb-3 shadow p-2" style="opacity: 75%;" align="center" style="background-color: rgba(255, 255, 255, 0.842); font-size: calc(100% +1vh);">
            Here are some other web and app based projects I've worked on 
            <hr>
        </section>
        
    </div>
<hr>


<div class="card mx-auto my-4 shadow text-left" style="background-color: rgba(255, 255, 255, 0.842)">
    <div class="container p-2">
        <h1 style="font-style: italic">
            Genealogy Ties
        </h1>
        <hr>
        <div class="row my-1 mx-auto" style="width: 100%">
            <div class="col col-12">
                <div class="col col-8 mx-auto">
                    <img class="border shadow rounded" src="assets/houseGood.png" style="width: 100%; object-fit: cover; cursor: pointer"
                    (click)="nav('https://genealogyties.com/')">
                    
                </div>
                <h2 class="lead text-left" style="font-size: calc(80% + 1vh)">
                    Genealogy ties is the first websit I designed, and what got me into HTML/CSS. It's a page to present the genealogy research my mother has done for our family,
                    and reaches back over 300 years back to when our family came to this country and before. It gave me a chance to practice my
                    web development skills as well as an opportunity to use photoshop to restore old photos
                </h2>
            </div>
            
        </div>
    </div>
</div>


