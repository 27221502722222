<div class="col col-11 shadow my-2 border mx-auto"
    style="border-radius: 5px; background-color: rgba(52, 159, 221, 0.582);">
    <h1 class="p-2 m-2 jumbotron mx-auto text-center"
        style="background-color: rgba(255, 255, 255, 0.842); font-style: italic; font-weight: bold">
        PROJECTS</h1>
        <div class="container pt-2 pb-0 col col-12">
            <section class="mb-3 " style="opacity: 75%;" align="center">
                
                <!-- Linkedin -->
                <a class="btn btn-outline-light btn-floating m-3 shadow bg-light" target="_blank"
                    href="https://github.com/r-silver1" role="button" style="border-radius: 3vw;"><img style="max-height: 5vmax"
                        src="https://img.icons8.com/nolan/64/github.png" /></a>
            
    
            </section>
            
        </div>
    <hr>
    <div class="card mx-auto my-4 shadow" style="background-color: rgba(255, 255, 255, 0.842); font-size: calc(100%)">
        <div class="container p-2">
            <div class="row  mx-auto" style="width: 100%">
                <div class="col col-6 mx-auto">
                    <img class="my-2 mx-auto img-responsive shadow" style="border-radius: 50%; width: 100%"
                        src="assets/pixel_assets_cy_resized.png">

                </div>
                
            </div>
            <div class="row mx-auto my-1" style="width: 100%">
                <h2 class="lead text text-center" style="font-style: italic; font-weight: bold; font-size: calc(100% + 1vh)">
                    Browse my Projects
                </h2>
                <p class="lead text-sm-center text-lg-left" style="font-size: calc(100% + 1vh)">
                       See the sidebar for the subsections of my projects portfolio. Go to graphics to check out some of the animations I've created
                       using WebGL/ThreeJS and vanilla JS. Read how COVID affected (or didn't affect) presidential approval ratings
                       and the numbers behind that conclussion in the AI/ML section. See links to other Web projects I've worked on in the "web" section

                </p>
            </div>
        </div>

    </div>
</div>