import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-portfolio',
  templateUrl: './ai-portfolio.component.html',
  styleUrls: ['./ai-portfolio.component.css']
})
export class AiPortfolioComponent implements OnInit {
  // <!-- https://o7planning.org/12065/bootstrap-embed -->
  constructor() { }

  ngOnInit(): void {
  }

}
