<footer class="text-white text-right pb-0" style="background-color: rgba(31, 75, 100, 0.322);">
    <div class="container pt-2 pb-0 col col-12">
        <section class="mb-3 mr-2" style="opacity: 75%;">
            <a style="visibility: hidden" href="https://icons8.com/icon/44019/linkedin">LinkedIn icon by Icons8</a>
            <a style="visibility: hidden" href="https://icons8.com/icon/52539/github">GitHub icon by Icons8</a>
            <!-- Linkedin -->
            <a class="btn btn-outline-light btn-floating m-1 shadow bg-light" target="_blank"
                href="https://github.com/r-silver1" role="button" style="border-radius: 3vw;"><img style="max-height: 5vmax"
                    src="https://img.icons8.com/nolan/64/github.png" /></a>
            <!-- Github-->
            <a class="btn btn-outline-light btn-floating m-1 shadow bg-light" target="_blank"
                href="https://www.linkedin.com/in/robert-elliot-silver/" role="button" style="border-radius: 3vw;"><img style="max-height: 5vmax"
                    src="https://img.icons8.com/cute-clipart/64/000000/linkedin.png" /></a>

        </section>
        
    </div>
    <div class="text-center col col-12 pt-4 pb-0" style="background-color: rgba(0, 0, 0, 0.2);">
        <p class="my-auto" style="font-size: 1vmax">© 2021 Copyright Robert Silver </p>

    </div>
</footer>