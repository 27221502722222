<div class="col col-11 shadow my-2 border mx-auto"
    style="border-radius: 5px; background-color: rgba(52, 159, 221, 0.582);">
    <h1 class="p-2 m-2 jumbotron mx-auto text-center"
        style="background-color: rgba(255, 255, 255, 0.842); font-style: italic; font-weight: bold">
        GRAPHICS </h1>
    <div class="container pt-2 pb-0 col col-12">
        <section class="lead card text-left mb-3 shadow p-2" style="opacity: 75%;" align="center"
            style="background-color: rgba(255, 255, 255, 0.842); font-size: calc(100% +1vh);">
            In this section I'll show off a few projects I particularly enjoyed developing. I built these graphics apps
            from the "ground up"
            using the WebGL and ThreeJS toolkits. One is a nod to a classic early FPS game, another is a
            not-so-scientific depiction
            of our solar system. Be sure to check out the controls for some advanced settings. I'm trying to think of
            what arcade game
            to remake next; right now I'm thinking either Snake, Lunar Lander or Asteroids. Drop me a DM which one you'd
            like to see!
            <hr>
            These apps were developed for use on a desktop browser and have not been adapted to be mobile friendly (yet)
        </section>

    </div>
    <hr>


    <div class="card mx-auto my-4 shadow text-left" style="background-color: rgba(255, 255, 255, 0.842)">
        <div class="container p-2">
            <h1 style="font-style: italic">
                WebGL: Counterstrike 1.6 inspired level redesign
            </h1>
            <hr>
            <div class="row my-1 mx-auto" style="width: 100%">
                <div class="col col-12">
                    <div class="col col-8 mx-auto">
                        <img (mouseenter)="showWarnCS=true" (mouseleave)="showWarnCS=false"
                            class="border shadow rounded" src="assets/csdeagle.jpg"
                            (click)="nav('http://www.projects.robertsilver.codes/cs-deagle-webgl/asg4.html')"
                            style="width: 100%; object-fit: cover; cursor: pointer">
                        <div *ngIf="showWarnCS" (mouseleave)="showWarnCS=false" class="alert alert-danger">Run on Chrome
                            Browser; Firefox/Safari implementation in progress</div>
                    </div>
                    <h2 class="lead text-left" style="font-size: calc(80% + 1vh)">
                        In this project I used textures taken from the game Counterstrike to remake one of my favorite
                        levels using JS/WebGL
                    </h2>
                </div>

            </div>
        </div>
    </div>

    <div class="card mx-auto my-4 shadow text-left" style="background-color: rgba(255, 255, 255, 0.842)">
        <div class="container p-2">
            <h1 style="font-style: italic">
                ThreeJS: Our Solar System
            </h1>
            <hr>
            <div class="row my-1 mx-auto" style="width: 100%">
                <div class="col col-12">
                    <div class="col col-8 mx-auto">
                        <img (mouseenter)="showWarnSol=true" (mouseleave)="showWarnSol=false"
                            class="border shadow rounded" src="assets/solarsystem.jpg"
                            (click)="nav('http://www.projects.robertsilver.codes/solar-system/')"
                            style="width: 100%; object-fit: cover; cursor: pointer">
                        <div *ngIf="showWarnSol" (mouseleave)="showWarnSol=false" class="alert alert-success">Should be
                            able to run on Safari and Firefox</div>
                    </div>
                    <h2 class="lead text-left" style="font-size: calc(80% + 1vh)">
                        In this project I use ThreeJs and include some fun 3D models to make a funky view of our solar
                        system
                    </h2>
                </div>

            </div>
        </div>
    </div>


</div>