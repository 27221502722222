<div class="col col-11 shadow my-2 border mx-auto"
    style="border-radius: 5px; background-color: rgba(52, 159, 221, 0.582);">
    <h1 class="p-2 m-2 jumbotron mx-auto text-center"
        style="background-color: rgba(255, 255, 255, 0.842); font-style: italic; font-weight: bold">
        ARTIFICIAL INTELLIGENCE </h1>
    <div class="container pt-2 pb-0 col col-12">
        <section class="lead card text-left mb-3 shadow p-2" style="opacity: 75%;" align="center"
            style="background-color: rgba(255, 255, 255, 0.842); font-size: calc(100% +1vh);">
            During my time at UC Santa Cruz I had the opportunity to take classes in Artificial Intelligence and Data
            mining and ever since I've been trying to keep learning more!
            Here are some examples of projects I worked on in the past. I hope you find them interesting and if you want
            to replicate the data I have included the Jupyter notebook files
            as well.
            <hr>
            One note: if you try to run the app which predicts presidential approval ratings given covid data and tweet
            information you may find you have some trouble. Since the creation of that app
            our former president's twitter account was banned, making that data inaccessible. I will work on rebuilding
            the app using an archive of the tweets if one exists.
        </section>

    </div>
    <hr>

    <div class="card mx-auto my-4 shadow text-left" style="background-color: rgba(255, 255, 255, 0.842)">
        <div class="container col-10  p-2">
            <h1 style="font-style: italic">
                A tale of tweets and ratings
            </h1>
            <hr>
            <div class="row my-1 mx-auto" style="width: 100%">
                <div class="col col-12">
                    <div class="col col-8 mx-auto">
                        <img class="shadow rounded" src="assets/wordcloud.png"
                            style="width: 100%; object-fit: cover">

                    </div>
                    <h2 class="lead m-2 text-left" style="font-size: calc(80% + 1vh)">
                        In this project my teammate Marques Reimann and I studied the correlation between COVID-19
                        cases, Donald Trump's tweet behavior and presidential
                        approval ratings during the early months of the COVID-19 pandemic
                    </h2>
                    <div class="rounded m-3 p-2 shadow" style=" background-color: rgba(52, 159, 221, 0.582);">
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#covidSlides" aria-controls="mainNavbar" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <div class="row px-1">
                                <img class="mr-auto my-auto" style="height: 3vh"
                                    src="https://img.icons8.com/ios/100/000000/menu--v1.png" />
                                <p class="my-auto ml-2 lead" style="font-size: calc(50% + 1vmin); color: aliceblue; font-style: italic">
                                    Slideshow Presentation</p>

                            </div>

                        </button>
                        <a style="visibility: hidden; z-index: -20" href="https://icons8.com/icon/3096/menu">Menu icon by Icons8</a>

                        <div class="collapse shadow rounded embed-responsive embed-responsive-16by9" id="covidSlides">
                            <iframe class="embed-responsive-item"
                                src="https://projects.robertsilver.codes/AI/covid-project/CSE145_FinalProject_slides.pdf" allowfullscreen></iframe>

                        </div>
                    </div>

                    <div class="rounded m-3 p-2 shadow" style=" background-color: rgba(52, 159, 221, 0.582);">
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#covidReport" aria-controls="mainNavbar" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <div class="row px-1">
                                <img class="mr-auto my-auto" style="height: 3vh"
                                    src="https://img.icons8.com/ios/100/000000/menu--v1.png" />
                                <p class="my-auto ml-2 lead" style="font-size: calc(50% + 1vmin); color: aliceblue; font-style: italic">
                                    Detailed Report</p>

                            </div>

                        </button>
                        <a style="visibility: hidden; z-index: -20" href="https://icons8.com/icon/3096/menu">Menu icon by Icons8</a>

                        <div class="collapse shadow rounded embed-responsive embed-responsive-16by9" id="covidReport">
                            <iframe class="embed-responsive-item"
                                src="https://projects.robertsilver.codes/AI/covid-project/CSE145_FinalReport.pdf" allowfullscreen></iframe>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


</div>
